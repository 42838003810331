import { useFormik } from "formik";
import { Form, FormControl, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { sharePoint } from "../../../apis/auth";
import * as Yup from "yup";
import classNames from "classnames";
import { toastSuccess, toastError } from "helpers/toastHelper";

const schema = Yup.object().shape({
  points: Yup.number()
    .required("Không được để trống")
    .positive("Điểm tặng là một số lớn hơn 0")
    .integer("Điểm tặng là một số lớn hơn 0")
    .truncate(),
});

const ModalSharePoint = ({ isOpen, closeModal, post }) => {
  const currentPost = useSelector((state) => state.postDetail.post.data);
  const current_user = useSelector(
    (state) => state.login.user_data.current_user
  );
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      points: "",
    },
    validationSchema: schema,
    onSubmit: async ({ points }) => {
      const params = {
        receiver_id: currentPost.id,
        points,
      };

      if (currentPost.user_id === current_user.id) {
        toastError("Không thể tặng chính mình");
        return;
      }
      try {
        const response = await sharePoint(params);
        if (response.data.success === true) {
          resetForm();
          closeModal();
        }
      } catch (error) {
        toastError(error.data.data);
      }
    },
  });

  return (
    currentPost && (
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Body closeButton>
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex align-items-center">
              <img
                src={current_user?.image ?? "/images/avatar.png"}
                alt=" images"
                className="avtar-sharepoint"
              />
              <div>
                <div className="txt-limit">
                  {current_user?.name ?? current_user?.shop?.name}
                </div>
                <div calssName="mt-2">
                  <img
                    src="/images/rice (1).png"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span className="ml-1">{current_user?.points}</span>
                </div>
              </div>
            </div>
            <div>
              <img
                src="/images/gift (1).png"
                style={{ width: "30px", height: "30px" }}
              />
            </div>
          </div>
          <Form>
            <div className="position-relative my-3">
              <FormControl
                name="points"
                autoFocus
                className={classNames({ "is-invalid": errors?.points })}
                placeholder={`Nhập số điểm cần tặng ${post.user_name}`}
                value={values.points}
                onChange={handleChange}
              />
              <button
                type="button"
                className="btn position-absolute  btn-sm btn-primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                style={{ top: "3px", right: "5px" }}
              >
                {" "}
                Tặng
              </button>
              {errors?.points && (
                <div className="invalid-feedback">{errors.points}</div>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  );
};

export default ModalSharePoint;
