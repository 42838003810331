import React, { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Avatar from "components/Avatar";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { sharePost } from "../../../apis/post";
import { toastSuccess } from "helpers/toastHelper";
import * as Yup from "yup";
import classNames from "classnames";
import AppContext from "components/app_context";

const schema = Yup.object().shape({
  description: Yup.string().required("Không được để trống"),
});

const ModalShare = ({ isOpen, closeModal }) => {
  const currentPost = useSelector((state) => state.postDetail.post.data);
  const { current_user } = useContext(AppContext);

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: schema,
    onSubmit: async (formValues) => {
      const data = {
        ...formValues,
        classable_id: currentPost.classable_id,
        classable_type: currentPost.classable_type,
        post_type: "public",
        share_type: "config_share",
      };
      try {
        const response = await sharePost(data);
        if (response.data.success === true) {
          toastSuccess(response.data.data);
          resetForm();
          closeModal();
        }
      } catch (e) {}
    },
  });

  return (
    currentPost && (
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header className="justify-content-center" closeButton>
          <Modal.Title>Viết bài</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <Avatar />
            <b className="ms-2">{current_user?.name}</b>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="my-3">
              <Form.Control
                as="textarea"
                className={classNames({ "is-invalid": errors?.description })}
                rows={3}
                name="description"
                value={values.description}
                onChange={handleChange}
                autoFocus
                placeholder="Bạn đang nghĩ gì"
              />
              {errors?.description && (
                <div className="invalid-feedback">{errors.description}</div>
              )}
            </div>
            <Button
              color="primary"
              className="w-100"
              type="submit"
              disabled={isSubmitting}
            >
              Chia sẻ
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  );
};

export default ModalShare;
