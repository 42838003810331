import { React, useState } from 'react';
import parse from 'html-react-parser';
import "../DiagnosticResult.scss"
import { FaExclamationCircle } from 'react-icons/fa';
import { Col, Row } from "react-bootstrap";

const SameDiagnosisResult = ({ data }) => {

  return (
    <>
      <h2 className='text-center'>Kết quả tương tự</h2>
      <div className="cont-center saved-items">
        <div className="box-message-info">
          <FaExclamationCircle className="info-icon" />
          <span className="info-message">
            Dữ liệu cây trồng đang được cập nhật, vui lòng kiểm tra cây trồng tương tự dưới đây
          </span>
        </div>
        <div className="list-suggest">
          <Row>
            {data.same_categories.map((category) => (
              <Col xs={6} md={3}>
                <img src={category.image} alt={category.name} className="box-info mt-3 box-info-image mb-2" />
                <strong>{category.name}</strong>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  )
}
export default SameDiagnosisResult;

