import React, {useEffect, useContext, useState} from "react";
import AppContext from "components/app_context";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "actions/auth";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { updatePhone, updateOtp } from 'apis/auth';

function modalUpdatePhone(props) {
  const { authen_token, isOpen, onToggle } = props;
  const { setUpdatedPhone } = useContext(AppContext);
  let [isClose, setIsClosePopup] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [popupTitle, setPopupTitle] = useState("Thêm số điện thoại");
  const [togglephoneInput, setTogglePhoneInput] = useState("form-control");
  const [toggleOtpInput, setToggleOtpInput] = useState("d-none");
  const [submitMethod, setSubmitMethod] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [countdown, setCountdown] = useState(60)

  useEffect(() => {
    localStorage.setItem('phone', '')
    setIsClosePopup(isOpen);
  }, [isOpen]);

  const buttonName = (name) => {
    return isLoading ? "Loading..." : name
  }

  const buttonOtpName = () => {
    return isOtpLoading ? `Chờ ${countdown}s` : "Gửi lại mã"
  }

  const submitPhone = (e) => {
    if (phone === null || phone === "null" || phone === ""){
      return toastError("Vui lòng nhập số điện thoại!");
    } else {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("phone", phone);
      updatePhone({ authen_token: authen_token, formData: formData }).then(res => {
        if (res?.data?.success){
          setPopupTitle("Nhập OTP")
          setTogglePhoneInput("d-none")
          setToggleOtpInput("")
          setSubmitMethod(true)
          setIsLoading(false)
        }else{
          setIsLoading(false)
          return toastError(res?.data);
        }
      })
    }
  };

  const submitOtp = (e) => {
    if (otp === null || otp === "null" || otp === ""){
      return toastError("Vui lòng nhập mã xác thực!");
    } else {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("phone", phone);
      formData.append("verify_code", otp);
      updateOtp({ authen_token: authen_token, formData: formData }).then(res => {
        if (res?.data?.success){
          setIsClosePopup(false)
          localStorage.setItem('phone', '')
          setUpdatedPhone(res?.data?.data?.phone)
          localStorage.setItem('phone', res?.data?.data?.phone)
          setIsLoading(false)
        }else{
          setIsLoading(false)
          return toastError(res?.data);
        }
      })
    }
  };

  const getOtp = (e) => {
    if (phone === null || phone === "null" || phone === ""){
      return toastError("Số điện thoại không tồn tại");
    }else{
      setCountdown(60);
      setIsOtpLoading(true);
      const formData = new FormData();
      formData.append("phone", phone);
      updatePhone({ authen_token: authen_token, formData: formData }).then(res => {
        if (res?.data?.success){
          return toastSuccess('Mã xác thực đã gửi cho bạn');
        }else{
          return toastError(res?.data);
        }
      })
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setIsOtpLoading(false);
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const submitButton = (e) => {
    if (!!submitMethod){
      return <button
        type="submit"
        className="btn btn-primary float-right mt-2 fw-semibold"
        onClick={submitOtp}
      >
        {buttonName("Đồng ý")}
      </button>

    }else{
      return <button
        type="submit"
        className="btn btn-primary float-right mt-2 fw-semibold"
        onClick={submitPhone}
      >
        {buttonName("Đồng ý")}
      </button>
    }
  }

  return (
    <>
      <Modal
        show={isClose}
        onHide={onToggle}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input className={togglephoneInput} type="text" onChange={(e) => setPhone(e.target.value)} />
          <div className={toggleOtpInput} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input style={{maxWidth: "82%"}} className="form-control" type="text" onChange={(e) => setOtp(e.target.value)} />
            <button
              className="btn btn-secondary float-right fw-semibold"
              onClick={getOtp}
              disabled={isOtpLoading}
            >
              {buttonOtpName()}
            </button>
          </div>
          {submitButton()}
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authen_token: state.user.user_data.authToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authActionsCreator: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(modalUpdatePhone);
